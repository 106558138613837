<template>
  <div v-if="statisticsReportModel" class="report">
    <div class="report-container">
      <!-- 第一页 -->
      <print-page
        :printHeaderInfo="printHeaderInfo"
        :currentPage="1"
        :totalPage="3"
      >
        <div>
          <div class="report-container-top">
            <div class="report-container-top-top">
              <p class="report-container-top-top-title">使用记录</p>
              <barcode
                v-if="statisticsReportModel.patientNum"
                :patientNum="statisticsReportModel.patientNum"
              />
            </div>
            <div class="report-container-top-bottom">
              <div class="report-container-top-bottom-left">
                <title-info-label
                  title="设备序列号"
                  :info="statisticsReportModel.deviceSN"
                />
                <title-info-label
                  title="设备型号"
                  :info="statisticsReportModel.deviceModel"
                />
              </div>
              <title-info-label
                title="统计时间"
                :info="statisticsReportModel.statisticsRange"
              />
            </div>
          </div>
          <report-info-block
            title="基本信息"
            :dataArray="statisticsReportModel.basicInfoArray"
            :widthPercent="100"
          ></report-info-block>
          <report-info-block
            title="设置信息"
            :dataArray="statisticsReportModel.paraArray[0].paraArray"
            :widthPercent="100"
          ></report-info-block>
        </div>
      </print-page>

      <!-- 第二页 -->
      <print-page
        :printHeaderInfo="printHeaderInfo"
        :currentPage="2"
        :totalPage="3"
      >
        <div class="test">
          <statistics-charts
            :chartData="statisticsReportModel.chartData"
            :isLite="true"
          ></statistics-charts>
        </div>
      </print-page>
      <!-- 第三页 -->
      <print-page
        :printHeaderInfo="printHeaderInfo"
        :currentPage="3"
        :totalPage="3"
      >
        <div>
          <input-box
            title="概述"
            :canSave="false"
            :inputContent="statisticsReportModel.abstract"
          ></input-box>
          <input-box
            title="建议"
            :canSave="false"
            :inputContent="statisticsReportModel.suggestion"
          ></input-box></div
      ></print-page>
    </div>
  </div>
</template>

<script>
import Barcode from "@c/report/barcode.vue";
import TitleInfoLabel from "@c/report/title-info-label.vue";
import ReportInfoBlock from "@c/report/report-info-block.vue";
import StatisticsCharts from "@c/report/charts/statistics-charts.vue";
import InputBox from "@c/report/input-box.vue";
import PrintPage from "@c/common/print-page.vue";
import { StatisticsReportModel } from "@js/model/statistics-report-model.js";
export default {
  components: {
    Barcode,
    TitleInfoLabel,
    ReportInfoBlock,
    StatisticsCharts,
    InputBox,
    PrintPage,
  },

  props: {
    reportTypeInitialInfo: Object,
    selectedInfo: Object,
  },

  data() {
    return {
      statisticsReportModel: undefined,
    };
  },

  computed: {
    //依赖statisticsReportModel
    printHeaderInfo() {
      return {
        patientName: this.statisticsReportModel?.model.user.name,
        deviceModel: this.statisticsReportModel?.deviceModel,
        deviceSN: this.statisticsReportModel?.deviceSN,
        statisticsTime: this.statisticsReportModel?.statisticsRange,
      };
    },
  },

  async created() {
    this.statisticsReportModel = await this.getReportDetail();
  },

  watch: {
    async selectedInfo() {
      this.statisticsReportModel = await this.getReportDetail();
    },
  },

  methods: {
    async getReportDetail() {
      try {
        this.statisticsReportModel = undefined;
        const params = {
          hospitalId: this.reportTypeInitialInfo.hospitalId,
          start: this.selectedInfo.reportDuration[0],
          end: this.selectedInfo.reportDuration[1],
          uid: this.reportTypeInitialInfo.uid,
        };
        const data = await this.$api.getReportStatisticsDetail(params);
        return new StatisticsReportModel(data);
      } catch (error) {
        this.$emit("onNoReport");
        return
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .report-container {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important ;
  }
  .no-print {
    display: none !important;
  }
}

.report {
  width: 100%;

  &-container {
    width: 900px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;

    &-top {
      background-color: #f7f7f7;
      box-sizing: border-box;
      padding-top: 33px;
      padding-bottom: 20px;
      width: 100%;
      height: 171px;

      &-top {
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 10px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          color: black;
          font-size: 48px;
          font-weight: bold;
          line-height: 33px;
        }
      }

      &-bottom {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;

        &-left {
          display: flex;

          :first-child {
            margin-right: 27px;
          }
        }
      }
    }
  }

  .test {
    font-size: 50px;
  }
}
</style>